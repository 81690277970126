import React from "react"
import Layout from "../../components/layout/layout"

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Layout>
        <div className="">
          <h3>Derek Pierce, Receiver</h3>
          <em>Managing Director, Healthcare Management Partners, LLC</em>
          <br />
          <br />
          <p>
            Derek has over 20 years of professional experience focused solely in
            the healthcare industry, having served as Chief Financial Officer,
            Chief Restructuring Officer, Receiver, Director of Reimbursement,
            court-appointed Examiner, Medicare auditor, Medicare cost report
            preparer, forensic accountant, compliance consultant, financial
            auditor, and financial advisor. He has audited, managed, and
            consulted with all types of healthcare providers including
            government owned, community not-for-profit, academic, and
            investor-owned entities.
          </p>
          <p>
            Derek served as the Restructuring Chief Financial Officer of a
            two-campus, not-for-profit hospital in Chapter 11 bankruptcy. In
            addition to his CFO responsibilities, he led the Section 363 sale
            process and conducted a forensic review into the circumstances that
            led to the hospital’s bankruptcy filing. Throughout the project,
            Derek worked closely with senior lenders, debtor-in-possession
            lenders and the applicable federal loan program to a successful plan
            of reorganization.
          </p>
          <p>
            Starting his career with the Medicare fiscal intermediary as a
            Medicare auditor, Derek developed expert knowledge of the patient
            revenue cycle, third-party contracting, and Medicare and Medicaid
            rules and regulations. He is an expert in the areas of corporate and
            regulatory compliance and forensic accounting related to the defense
            of civil and criminal fraud claims or allegations. He led teams
            conducting forensic analysis in support of settlement negotiations
            with the government at both Hospital Corporation of America (HCA)
            and HealthSouth Corporation, two of the largest and most complex
            civil fraud settlements in history.
          </p>
          <p>
            During 2012 to 2016, Derek served as the CFO in the extremely
            successful turnarounds of two separate, large multi-site eldercare
            providers. Both of these organizations operated skilled nursing,
            assisted living, inpatient rehabilitation, home care, hospice,
            inpatient behavioral health, and a PACE program. Separately, Derek
            has served as either the trustee or receiver in the liquidation and
            sale of a number of nursing homes.
          </p>
          <p>
            In his career, Derek has led or participated in turnaround
            management and consulting assignments for more than 40 healthcare
            providers, including Baptist Memorial Health Care, British National
            Health Service, Washington Hospital Center, Tri-Lakes Hospital,
            Lifepoint Hospitals, Tampa General Hospital, Legacy Health System,
            Loretto, American Healthcare Companies, and IASIS Healthcare. Derek
            is currently serving as the Chief Restructuring Officer of a
            multi-site nursing home company in Hawaii.
          </p>
          <p>
            Prior to joining HMP, Derek was a Director with Alvarez & Marsal in
            its New York-based healthcare practice. Before that, he was a Senior
            Manager with Arthur Andersen in its Atlanta-based healthcare
            consulting practice.
          </p>
          <p>
            Derek is a graduate of Samford University with a Bachelor of Science
            in Accounting and is a member of numerous professional associations.
          </p>
        </div>
      </Layout>
    )
  }
}
